$font-family-base: var(--bs-font-monospace);

.brand-image {
    height: calc(var(--bs-navbar-brand-font-size)*4);
}

.small-text {
    font-size: 0.6rem;
}

.mid-text {
    font-size: 0.7rem;
}

.mid8-text {
    font-size: 0.8rem;
}

@import '~bootstrap/scss/bootstrap';
